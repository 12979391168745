<template>
  <div>
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="createCategoryHandler"
          icon-pack="feather"
          icon="icon-edit"
        >Add Reporting Category</vs-button>
      </div>
    </div>
    <CategoryList
      :categoryData="categories"
      :totalDocs="totalDocs"
      :page="page"
      :popUpDeleteParent="popUpDelete"
      :noDataText="noDataText"
      @fetchList="getCategoriesList"
      @editCategory="editCategory"
      @deleteCategory = "deleteCategory"
    />
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import CategoryList from "./list";

  export default {
    components: {
      CategoryList
    },
    data() {
      return {
        categories:[],
        totalDocs: 0,
        page: 1,
        popUpDelete:false,
        categoryData: [],
        noDataText: 'Loading...'
      };
    },
    methods: {
      async getCategoriesList(dataTableParams) {
        this.noDataText = 'Loading...'
        const res = await this.$store.dispatch('reportingCategory/getAllReportingCategory', dataTableParams)
        this.categories = res.docs;
        this.totalDocs = res.pagination.total || 0;
        this.page = res.pagination.page;
        this.currentPage = res.pagination.page;
        this.noDataText = 'No Category Available'
      },
      createCategoryHandler(){
        this.$router.push({ name: 'AddReportingCategory'})
      },
      editCategory(id) {
        this.$router.push({
          name: "EditReportingCategory",
          params: { reportingCategoryId: id }
        });
      },
      async deleteCategory(data){
        try {
          await this.$store.dispatch("reportingCategory/deleteReportingCategoryById", data.id);
          this.popUpDelete = false;
          this.getCategoriesList(data.dataTableParams);
          this.$vs.notify({
            title: "Success",
            text: "Reporting Category Deleted successfully.",
            color: "success"
          });
        } catch (error) {
          this.popUpDelete = false;
          this.$vs.notify({
            title: error.data.title,
            text: error.data.message,
            color: "danger"
          });
        }
      }
    }
  };
</script>
